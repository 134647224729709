import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { API_ROUTES } from '../../../utils/APIs'
import ROUTES_URL from '../../../constant/routes'
import { Error, Success } from '../../../common/alert'
import { TextField } from '../../../component/InputField'
import { ArrowRight, Loader } from '../../../common/Icons'
import { forgotPasswordOTPVerifyValidation } from '../../../constant/validation'

function OTPVerify() {
  let navigate = useNavigate()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState(null)

  useEffect(() => {
    const email = localStorage.getItem('forgot-email')
    if (email) {
      setDefaultEmail(JSON.parse(email))
    } else {
      setDefaultEmail('')
    }
  }, [])

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const response = await axios.post(API_ROUTES.VERIFY_FORGOT_PASSWORD_OTP, values)
      if (response.data.status === 200) {
        setError(null)
        navigate(ROUTES_URL.RESET_PASSWORD)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setError(error.response.data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-grayLight min-h-screen h-full p-4 flex justify-center items-center">
      <div className="bg-white max-w-[480px] w-full border border-gray-300 rounded-md px-4 py-8">
        <div className="">
          <div>
            <div className="text-xl font-bold">OTP Verify</div>

            <div className="mt-5">
              {error && <Error message={error} />}
              {defaultEmail !== null ? (
                <Formik
                  initialValues={{
                    email: defaultEmail || '',
                    otp: '',
                  }}
                  validationSchema={forgotPasswordOTPVerifyValidation}
                  onSubmit={(values) => {
                    handleSubmit(values)
                  }}
                >
                  {({ isSubmitting, setFieldValue, isValid }) => (
                    <Form>
                      <TextField
                        type="email"
                        label="email"
                        name="email"
                        placeholder="youremail@domain.com"
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      <TextField
                        type="otp"
                        label="OTP"
                        name="otp"
                        placeholder="****"
                        onChange={(e) => setFieldValue('otp', e.target.value)}
                      />

                      <button
                        type="submit"
                        className={`flex gap-x-2 justify-center items-center rounded w-full p-2 mt-4 ${
                          isValid ? 'bg-blue' : 'bg-[#adbfdd]'
                        }`}
                        // disabled={isValid ? false : true}
                      >
                        {loading ? (
                          <Loader />
                        ) : (
                          <>
                            <span className="text-white text-base font-bold capitalize">
                              continue
                            </span>
                            <ArrowRight />
                          </>
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="h-10 flex justify-center items-center">
                  <Loader color="#2557a7" />
                </div>
              )}
            </div>

            <div className="flex gap-x-1 justify-end text-sm mt-2">
              <span className="text-gray-400">Back to</span>
              <Link to={ROUTES_URL.SIGN_IN}>
                <span className="text-blue font-bold uppercase">Login</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OTPVerify
