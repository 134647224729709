import { ArrowRight, Loader } from '../../common/Icons'
import { Link, useNavigate } from 'react-router-dom'
import ROUTES_URL from '../../constant/routes'
import { Form, Formik } from 'formik'
import { TextField } from '../../component/InputField'
import { signInValidation } from '../../constant/validation'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Error, Success } from '../../common/alert'
import { API_ROUTES } from '../../utils/APIs'

function SignIn() {
  let navigate = useNavigate()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [isVerify, setIsVerify] = useState(false)

  useEffect(() => {
    const verifyStatus = localStorage.getItem('verify')
    if (verifyStatus) {
      setIsVerify(true)
    } else {
      setIsVerify(false)
    }
  }, [isVerify])

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const response = await axios.post(API_ROUTES.SIGN_IN, values)
      if (response.data.status === 200) {
        setError(null)
        localStorage.removeItem('verify')
        localStorage.setItem('token', response.data.data.token)
        localStorage.setItem('user', JSON.stringify(response.data.data.user))
        navigate(ROUTES_URL.HOME)
        setLoading(false)
      }
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-grayLight min-h-screen h-full p-4 flex justify-center items-center">
      <div className="bg-white max-w-[480px] w-full border border-gray-300 rounded-md px-4 py-8">
        <div className="">
          <div>
            <div className="text-xl font-bold">
              Ready to take the next step?
            </div>
            <div className="text-base font-normal text-gray-500 mt-5">
              Sign in account to continue...
            </div>
            <div className="text-xs font-normal text-gray-500 mt-2">
              By creating an account or signing in, you understand and agree to
              JobSite's Terms. You also consent to our Cookie and Privacy
              policies. You will receive marketing messages from JobSite and may
              opt out at any time by following the unsubscribe link in our
              messages, or as detailed in our terms.
            </div>
            
            <div className="mt-5">
              {error && <Error message={error} />}
              {isVerify && (
                <Success message=" Email verified successful! please login to continue..." />
              )}
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={signInValidation}
                onSubmit={(values) => {
                  handleSubmit(values)
                }}
              >
                {({ isSubmitting, setFieldValue, isValid }) => (
                  <Form>
                    <TextField
                      type="email"
                      label="email"
                      name="email"
                      placeholder="youremail@domain.com"
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                    <TextField
                      type="password"
                      label="password"
                      name="password"
                      placeholder="* * * * * * * *"
                      onChange={(e) =>
                        setFieldValue('password', e.target.value)
                      }
                    />
                    <div className='text-right text-sm text-blue cursor-pointer underline' onClick={() => navigate(ROUTES_URL.FORGOT_PASSWORD)}>Forgot Password</div>
                    <button
                      className={`flex gap-x-2 justify-center items-center rounded w-full p-2 mt-4 ${
                        isValid ? 'bg-blue' : 'bg-[#adbfdd]'
                      }`}
                      disabled={isValid ? false : true}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <span className="text-white text-base font-bold capitalize">
                            continue
                          </span>
                          <ArrowRight />
                        </>
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="flex gap-x-1 justify-end text-sm mt-2">
              <span className="text-gray-400">Don't have account</span>
              <Link to={ROUTES_URL.SIGN_UP}>
                <span className="text-blue font-bold uppercase">Signup</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
