import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const DateFormat = ({ field, form, ...props }) => {
  const { name } = field;
  const { setFieldValue } = form;

  return (
      <DatePicker
        selected={field.value ? new Date(field.value) : null}
        onChange={date => setFieldValue(name, date ? date.toISOString().split('T')[0] : '')}
        dateFormat="yyyy-MM-dd"
        placeholderText='YYYY-MM-DD'
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        {...props}
      />
  );
};


export const ViewDateFormat = ({ date }) => {
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toISOString().split('T')[0]; // Formats to YYYY-MM-DD
  };

  return (
    <span>{formatDate(date)}</span>
  );
};