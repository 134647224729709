import {
  ArrowLeftBlue,
  ArrowRight,
  Loader,
  SuccessIcon,
} from '../../common/Icons'
import ROUTES_URL from '../../constant/routes'
import { useNavigate } from 'react-router-dom'
import { TextField } from '../../component/InputField'
import { Form, Formik } from 'formik'
import { otpVerifyValidation } from '../../constant/validation'
import { useState } from 'react'
import axios from 'axios'
import { Error } from '../../common/alert'
import { API_ROUTES } from '../../utils/APIs'

function Verify() {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    setLoading(true)
    const email = localStorage.getItem('verify-email')
    try {
      const response = await axios.post(API_ROUTES.VERIFY_OTP, {
        email: email,
        otp: values.otp,
      })
      if (response.data.status === 200) {
        setError(null)
        localStorage.removeItem('verify-email')
        localStorage.setItem('verify', true)
        navigate(ROUTES_URL.SIGN_IN)
      }
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-grayLight min-h-screen h-full p-4 flex justify-center items-center">
      <div className="bg-white max-w-[480px] w-full border border-gray-300 rounded-md px-4 py-8">
        <button className="flex items-center gap-x-1" onClick={() => navigate(ROUTES_URL.SIGN_UP)}>
          <ArrowLeftBlue />
          <span className="text-blue font-semibold">Back</span>
        </button>
        <div className="text-xl font-bold mt-2">Sign in with login code</div>
        <div className="text-base text-gray-400 mt-1">
          We have sent your one-time code to your email.
        </div>
        <div className="flex text-base font-bold mt-2">
          <label>
            Enter 4-digit code <span className="text-red-900">*</span>
          </label>
        </div>
        <div>
          {error && <Error message={error} />}
          <Formik
            initialValues={{
              otp: '',
            }}
            validationSchema={otpVerifyValidation}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({ setFieldValue, isValid }) => (
              <Form>
                <TextField
                  type="otp"
                  name="otp"
                  placeholder="* * * *"
                  onChange={(e) => setFieldValue('otp', e.target.value)}
                />

                <button
                  className={`flex gap-x-2 justify-center items-center rounded w-full p-2 mt-4 ${
                    isValid ? 'bg-blue' : 'bg-[#adbfdd]'
                  }`}
                  disabled={isValid ? false : true || loading ? false : true}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <span className="text-white text-base font-bold">
                        Sign in
                      </span>
                      <ArrowRight />
                    </>
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <button
          disabled
          className="bg-grayLight border border-gray-300 p-2 w-auto flex gap-x-2 items-center mt-10"
        >
          <div className="p-1 bg-green-800 rounded-full h-7 w-7 flex justify-center items-center">
            <SuccessIcon />
          </div>
          <div className="text-sm text-gray-500 font-extralight">Success!</div>
        </button>
        <div className="text-base mt-1">Didn't receive your code?</div>
        <button className="text-base font-bold text-blue mt-2">
          Send new code
        </button>

        <button className="text-base font-bold text-blue mt-4">
          Don't have access to this email
        </button>
      </div>
    </div>
  )
}

export default Verify
